@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css");

:root {
  --main-font: "Rajdhani";
  #dark {
    --lighter: #cbd6f0;
    --accent: #6971cd;
    --background-primary: #0a0d14;
    --background-secondary: #141a29;
    --background-secondary-alt: #101628;
    --background-secondary-altlight: #304072;
    --foreground-primary: #a8b5c3;
    --purple: #492dd5ec;
    --accgreen: #07816c;
    --greylight: #a7afd7;
  }
  #light {
    --lighter: #0a0d14;
    --accent: #6971cd;
    --background-primary: #cbd6f0;
    --background-secondary: #cbd6f0;
    --background-secondary-alt: #cbd6f0;
    --background-secondary-altlight: #98b0e8;
    --foreground-primary: #141a29;
    --purple: #492dd5ec;
    --accgreen: #07816c;
    --greylight: #a7afd7;
  }
  transition: background-color 2s ease;
}

.acc-text {
  color: var(--accent);
}
.blue-text {
  font-weight: bold;
  color: var(--accent);
}

.reg-text {
  align-items: center;
  color: #a8b5c3;
}

.general-header {
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 2px;
  color: var(--lighter);
  &:focus,
  &:hover {
    color: transparent !important;
    // background: url("https://media.giphy.com/media/3bc8pP1rVdfgN1uoMV/giphy.gif");
    background: url("https://media.giphy.com/media/9JxkPTP3alOykb8PmQ/giphy.gif") !important;
    // background: url("https://media.giphy.com/media/lKaeQAunM3hZaqsOpj/giphy.gif?cid=ecf05e47xuf9z7vq1a8iarlescw065qcad2bri7zfgzgemzh&ep=v1_gifs_search&rid=giphy.gif&ct=g");
    background-size: cover !important;
    background-position: center !important;
    -webkit-background-clip: text !important;
  }
}

.name-text {
  font-weight: bold;
  letter-spacing: 2px;
  color: var(--accent);
  &:hover {
    color: transparent;
    // background: url("https://media.giphy.com/media/3bc8pP1rVdfgN1uoMV/giphy.gif");
    background: url("https://media.giphy.com/media/9JxkPTP3alOykb8PmQ/giphy.gif");
    // background: url("https://media.giphy.com/media/lKaeQAunM3hZaqsOpj/giphy.gif?cid=ecf05e47xuf9z7vq1a8iarlescw065qcad2bri7zfgzgemzh&ep=v1_gifs_search&rid=giphy.gif&ct=g");
    // background: url("https://media.giphy.com/media/3bc8pP1rVdfgN1uoMV/giphy.gif");
    // background: url("https://media.giphy.com/media/Qvp6Z2fidQR34IcwQ5/giphy.gif");
    background-size: cover;
    background-position: center;
    -webkit-background-clip: text;
  }
}
.name-text.colormode {
  color: transparent;
  // background: url("https://media.giphy.com/media/Qvp6Z2fidQR34IcwQ5/giphy.gif");
  background: url("https://media.giphy.com/media/9JxkPTP3alOykb8PmQ/giphy.gif");
  background-size: cover;
  background-position: center;
  -webkit-background-clip: text;
}

.domain-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80vw;
}

$radius: 10px;

.gbutton {
  display: inline-block;
  position: relative;
  padding: 0.6em 40px;
  font-size: 16px;
  color: var(--accent);
  border-radius: $radius;
  letter-spacing: 2px;
  cursor: url("../../public/assets/files/hand.svg"), pointer;
  z-index: 1;
  border: currentColor 0.12em solid;
}

.gbutton.is-domain,
.gbutton.is-domain.is-burger {
  // align-items: center;
  // justify-content: center;
  margin: 10px;
}

.gbutton.is-domain {
  width: 20vw;
}
.gbutton.is-domain.is-burger {
  display: inline-flex;
  height: 70px;
  width: 40vw;
}

.gbutton.is-mile {
  padding: 5px 20px;
  left: -1px;
  bottom: 10px;
}

.gbutton::before,
.gbutton::after {
  content: "";
  position: absolute;
  border-radius: $radius;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.gbutton::before {
  top: -0.015em;
  left: -0.02em;
  right: -0.02em;
  bottom: -0.015em;
  opacity: 0.3;
  background: linear-gradient(
    -45deg,
    var(--accent),
    var(--lighter),
    var(--purple),
    var(--accent)
  );
  background-size: 200%;
  filter: blur(20px);
  animation: glow 3s linear infinite;
  transition: opacity 200ms linear;
}

.gbutton:hover::before {
  opacity: 0.4;
}

.gbutton::after {
  opacity: 1;
  background-color: var(--background-primary);
  transition: opacity 10ms linear, background-color 10ms linear;
}

.gbutton:hover::after {
  opacity: 0.8;
}

.gbutton.is-burger::before {
  opacity: 0.5;
}
.gbutton.is-burger:focus::before,
.gbutton.is-burger:active::before {
  opacity: 1;
}

.gbutton-text {
  color: var(--accent);
  text-shadow: 0 0 0.1em currentColor, 0 0 0.02em white;
  transition: color 1s linear;
}

.gbutton:hover .gbutton-text {
  color: var(--lighter);
}

.gbutton:focus,
.gbutton:hover {
  border: currentColor 0.1em solid;
  text-shadow: 0 0 0.2em currentColor;
  z-index: 1;
  opacity: 1;
}

@keyframes glow {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: 0%;
  }
}

.nbutton {
  display: inline-block;
  position: relative;
  padding: 0.6em 40px;
  font-size: 16px;
  color: var(--accent);
  border-radius: $radius;
  letter-spacing: 2px;
  cursor: url("../../public/assets/files/hand.svg"), pointer;
  z-index: 1;
  margin: 0.5em;
  margin-top: 1.5em;
  border: currentColor 0.12em solid;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.nbutton::before,
.nbutton::after {
  content: "";
  position: absolute;
  border-radius: $radius;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.nbutton::before {
  top: -0.015em;
  left: -0.02em;
  right: -0.02em;
  bottom: -0.015em;
  opacity: 0;
  background: linear-gradient(
    -45deg,
    var(--accent),
    var(--lighter),
    var(--purple),
    var(--accent)
  );
  background-size: 200%;
}

.nbutton::after {
  opacity: 1;
  background-color: var(--background-primary);
}

.nbutton:hover::before {
  opacity: 0.3;
  filter: blur(10px);
  animation: glow 10s linear infinite;
}

.nbutton:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 12px rgba(51, 54, 112, 0.477);
  .nbutton-text {
    color: var(--lighter);
    transition: color 1.5s ease;
  }
}

.nbutton-text {
  color: var(--accent);
  text-shadow: 0 0 0.1em currentColor, 0 0 0.02em white;
  cursor: url("../../public/assets/files/hand.svg"), pointer;
}
// .nbutton-text:hover {
//   color: var(--lighter);
//   transition: color 1s ease;
// }

.nbutton.is-mile {
  padding: 5px 20px;
  left: -1px;
  bottom: 10px;
}

.linebox {
  position: relative;
  --border-size: 2.8px;
  --border-angle: 0turn;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 10px 10px 10px 10px;
  background-image: conic-gradient(
      from var(--border-angle),
      var(--background-primary),
      var(--background-primary) 100%,
      var(--background-primary)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 40%,
      var(--accent),
      rgb(68, 64, 178)
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-color 2s ease;
  border-radius: 2.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  animation: bg-spin 5s linear infinite;
  animation-iteration-count: 2;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  &:hover {
    animation-play-state: paused;
    --border-size: 2px;
  }
}

.divebox {
  position: relative;
  --border-size: 2.8px;
  --border-angle: 0turn;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 10px 10px 10px 10px;
  background-image: conic-gradient(
      from var(--border-angle),
      var(--background-primary),
      var(--background-primary) 100%,
      var(--background-primary)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 40%,
      var(--accent),
      rgb(68, 64, 178)
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-color 2s ease;
  border-radius: 2.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  animation: bg-spin 5s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  &:hover {
    animation-play-state: paused;
    --border-size: 2px;
  }
}

.divebox.is-burger {
  visibility: visible;
  width: 60vw;
  margin-bottom: 20px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.divebox.is-domain {
  width: 300px;
}
.divebox.is-domain.is-burger {
  height: 70px;
}
.divebox.is-mile {
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px 0 0 0;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.dive {
  color: var(--accent);
  font-size: 18px;
  -webkit-filter: blur(0.2px);
  &:hover {
    -webkit-filter: none;
    cursor: url("../../public/assets/files/hand.svg"), pointer;
  }
}

.hyperlink {
  font-weight: bold;
  transition: 0.4s linear;
  transform: translateY(-0rem);

  &:hover {
    transform: translateY(-0.5rem);
    cursor: url("../../public/assets/files/hand.svg"), pointer;
  }
}

.box {
  color: var(--foreground-primary);
  background-color: var(--background-primary);
  border: 3px solid var(--background-secondary-alt);

  display: inline-block;
  width: 100%;
  height: 100%;

  span {
    font-size: 0.875rem;
    font-weight: bold;
    padding: 8px;
  }
}

.recompanel {
  display: flex;
  flex-direction: column;
}
.recom-count {
  text-align: center;
  margin: 20px 0;
}

.recomsearchpanel {
  display: flex;
  flex-direction: row;
  margin: 1em;
}
.recombuttonpanel {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding-left: 20px;
}
.recombutton {
  width: 200px;
  color: var(--ligter);
  margin-right: 1em;
  margin-top: 10px;
  border: var(--lighter) 0.12em solid;
}
.recominput {
  font-size: 15px;
  width: 40%;
  border: 1px solid hsl(219, 92%, 85%);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.recomchangeinput {
  font-size: 16px;
  width: 50vw;
  color: white;
  background-color: var(--background-secondary);
  border: 1px solid hsl(219, 92%, 85%);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 10px;
}
.recomselect {
  width: 20%;
  font-size: 15px;
  padding-top: 20px;
  margin-left: 1em;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid hsl(219, 92%, 85%);
  box-sizing: border-box;
}

.recom-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  inline-size: 50vw;
  margin-inline: auto;
}
.recom-cards.mobile {
  flex-direction: column;
  inline-size: 98vw;
}
.recom {
  color: var(--lighter) !important;
}
.recom-card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  border-radius: 0.8rem;
  margin: 1rem 1rem;
  padding: 1rem 1rem;
  box-shadow: 0 0 0 0.09em var(--accent);
  transition: background-color 2s ease, box-shadow 0.8s ease;
  .recom-card-content {
    justify-content: space-between;
    align-items: center;
    padding-right: 2em;
    height: auto;
    .recom-card-bar {
      display: inline-flex;
      flex-direction: column;
      padding: 0 0.8em;
    }
    .recom-title {
      padding: 0.5em;
      font-size: 19px;
      font-weight: bold;
    }
    .recom-date {
      padding: 1em;
      font-size: 12px;
    }
    .recom-img {
      width: 2em;
      height: 2em;
      padding: 2px;
    }
  }
}
.recom-card.mobile {
  width: 70vw;
}
.recom-card:hover {
  background-color: var(--background-primary);
}
.recomdesctext {
  font-size: 17px;
  padding: 10px;
  color: white;
  background-color: var(--background-secondary);
  letter-spacing: 2px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  border-color: var(--lighter);
  height: auto;
  min-height: 100px;
  :focus {
    border-color: hsl(233, 100%, 84%);
  }
}

.recomdescfield {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(120, 116, 116, 0.1);
}

.form input,
.form textarea {
  width: 40%;
  padding: 10px;
  border: 1px solid hsl(219, 92%, 85%);
  box-sizing: border-box;
}

.contactname {
  font-size: 18px;
  padding: 10px;
  height: 6vh;
  color: white;
  background-color: var(--background-primary);
  letter-spacing: 2px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  :focus {
    border-color: hsl(233, 100%, 84%);
  }
}

.contactmessage {
  font-size: 18px;
  padding: 10px;
  height: 20vh;
  color: white;
  background-color: var(--background-primary);
  letter-spacing: 2px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  :focus {
    border-color: hsl(233, 100%, 84%);
  }
}

.sayhi {
  font-size: 24px;
  font-weight: 700;
}

.popup {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px;
}

.progress-bar {
  position: fixed;
  background-color: grey;
  height: 50%;
  width: 0.1em;
  border-radius: 1em;
  top: 30%;
  right: 2%;
  z-index: 10;
  opacity: 0.5;
  transition: opacity 1s linear;
}
.progress-bar.hid {
  opacity: 0;
}
.progress-bar:hover {
  opacity: 0.7;
}
.filled {
  background-image: linear-gradient(
    to bottom,
    black,
    blue,
    var(--accent),
    var(--lighter)
  );
  border-radius: 1em;
}
